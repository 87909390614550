import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Home from './components/home';
import IdpbRedirect from './components/sso-idpb/Idpb-redirect';

const Unauthorized = () => {
   return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
        }}>
           <h1>400 - Bad Request</h1>
        </div>
    )
};

const AppRouter = () => {
  return (
    <Switch>
      <Route exact path="/" component={Unauthorized} />
      <Route exact path="/sso/session/:tenant" render={(props) => <IdpbRedirect {...props} />} />
      <Route path="/:appId/*" component={Home} />
      {/* Fallback route for unmatched paths */}
      <Redirect to="/" />
    </Switch>
  );
};

export default AppRouter;
